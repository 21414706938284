import './ResumeUpload.css';
import { api, getTenant, getUserId } from '@iblai/ibl-web-react-common';
import { useRef } from 'react';

const ResumeUpload = ({
  setResumeUploaded,
  setErrorMessage,
  setSelectedFile,
  selectedFile,
  errorMessage,
  resumeUploaded,
}) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > 5242880) {
      // 5MB in bytes
      setErrorMessage('File size should be less than 5MB');
    } else if (
      file &&
      ![
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ].includes(file.type)
    ) {
      setErrorMessage(
        'Invalid file type. Please upload a PDF, DOC, or DOCX file.'
      );
    } else {
      handleSubmit(file);
    }
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleFileChange({ target: { files: [file] } });
  };

  const handleSubmit = (file) => {
    if (file) {
      const formData = new FormData();
      formData.append('resume', file);
      formData.append('org', getTenant());
      formData.append('userID', getUserId());
      formData.append('user', getUserId());
      formData.append('platform', getTenant());

      setResumeUploaded(false);
      setErrorMessage('');

      api.ibldmplatform.uploadResume(
        { org: getTenant(), userID: getUserId() },
        formData,
        (data) => {
          setResumeUploaded(true);
          setSelectedFile(file);
          setErrorMessage('');
        },
        (error) => {
          setResumeUploaded(true);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          if (error?.status === 400) {
            setErrorMessage('User resume with this user already exists');
            return;
          }
          window.alert('Error uploading resume. Please try again later.');
          console.error(error);
        }
      );
    }
  };

  return (
    <>
      <div className="div-block-78 res">
        <div className="div-block-84 level_rate">
          <div className="text-block-62 res">Upload Your Resume (Optional)</div>
          <div className="text-block-147">
            Our AI Engine will detect and add your skills to your learning
            profile
          </div>
          <div
            className="div-block-233"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (fileInputRef.current) {
                fileInputRef.current.click();
              }
            }}
            onDrop={handleFileDrop}
            onDragOver={(e) => e.preventDefault()}
          >
            <div className="div-block-234">
              <div className="div-block-235">
                <img
                  src="/images/upload.png"
                  loading="lazy"
                  alt=""
                  className="image-93"
                />
              </div>
              <div>
                <div className="text-block-148">Drag and drop your resume</div>
                <div className="text-block-149">
                  (pdf, doc, docx; up to 5MB)
                </div>

                <>
                  <input
                    type="file"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    id="fileInput"
                  />
                </>
              </div>
            </div>
            {selectedFile && (
              <div className="file-name">{selectedFile.name}</div>
            )}
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResumeUpload;
